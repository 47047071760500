@mixin contactText {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  max-width: 36.25rem;
  font-weight: 500;
  color: #333;
}

.contact {
  width: 100%;
  margin-top: 5.625rem;
  max-width: 77.5rem;
  margin: 5.625rem auto 0 auto;
}

.banner {
  display: flex;
  justify-content: center;
  flex-direction: column;

  height: 21.25rem;
  background: #fff;
  background-image: url("../../assets/images/contact-bg.jpg");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  border-bottom: 2px solid #f0f3fc;
  margin-bottom: 2.5rem;
}

.heading {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
  color: #000;
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2.875rem;
  max-width: 36.25rem;
  font-weight: 500;
  color: #000;
}

.contactForm {
  width: 100%;
  display: flex;
  max-width: 77.5rem;
  justify-content: space-between;
  padding: 1rem 0 4rem 0;
  gap: 3.75rem;

  .form {
    width: 60%;

    label {
      font-size: .875rem;
      line-height: 1.5rem;
      max-width: 36.25rem;
      font-weight: 600;
      color: #333;
    }

    :global(.ant-input) {
      height: 2.5rem;
    }

    textarea {
      height: auto !important;
    }
  }
}

.subHeading {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 2.5rem;
  position: relative;
  padding-bottom: 0.625rem;

  &:after {
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
    background: #ea8112;
  }
}

.submitBtn {
  background: #ea8112;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration: none;
  height: auto;
  &:hover {
    background: #425b76 !important;
    border-color: #425b76 !important;
    color: #fff !important;
  }
}

.contactInfo {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  p {
    @include contactText;
    &:hover {
      transition: color 0.3s;
      color: #ea8112;
    }
    &:hover::after {
      width: 100%;
    }
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: #ea8112;
      transition: width 0.3s;
    }
  }

  :global(.ant-image) {
    background: #425b76;
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.contactDetails {
  max-width: 25rem;
}

.message {
  @include contactText;

  font-size: .9375rem;

  max-width: 36.25rem;
  margin-bottom: 1.5rem;
}
