$brand: #ea8112;
$white: #ffffff;
$grey: #5b5c61;

@mixin alignFlex {
  display: flex !important;
  align-items: center !important;
}

.container {  
  & > ul {
    bottom: 2.25rem !important;
  }

  :global(.slick-dots li.slick-active button) {
    border: 4px solid $brand;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }

  :global(.slick-dots li button) {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
  }

  :global(.slick-dots li),
  :global(.slick-dots li.active) {
    width: 0.75rem !important;
  }
}

.wrapper {
  @include alignFlex;
  height: 38.75rem;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 5.625rem;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;

  &.image-1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
    url(../../assets/images/home/home1.jpg);
  }

  &.image-2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
    url("../../assets/images/home/home2.svg");
  }

  &.image-3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
    url("../../assets/images/home/home3.svg");
  }

  &.image-4 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
    url("../../assets/images/home/home4.svg");
  }

  &.image-5 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
    url("../../assets/images/home/home5.jpg");
  }

  &.image-6 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
    url("../../assets/images/home/home7.jpg");
  }

  &::before {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: #00000080;
  }
}

.content {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  text-align: left !important;
  z-index: 1;

  padding: 5rem 0;
  width: 77.5rem;
  margin: 0 auto;
}

.title {
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  color: #FFA200;
  text-shadow: 0 1px 0 #ea8112;
}

.subTitle {
  font-size: 1.125rem;
  color: $white;
  padding: .5rem 1.25rem;
  background-color: rgb(0 0 0 / 63%);
  border-left: 2px solid $white;
  margin-bottom: .625rem;
  font-weight: bold;
}

.text {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
  max-width: 36.25rem;
  font-weight: 500;
  color: $white;
  text-shadow: 0 1px 0 #425b76;
}

.actionBtns {
  display: flex;
  gap: 2.5rem;
}


.servicesBtn,
.contactBtn {
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  color: $white;
}

.servicesBtn {
  background: $brand;
}

.contactBtn {
  background: #fff;
  color: #333;
}

.contactBtn,
.servicesBtn {
  &:hover {
    color: $white !important;
    background: #425b76 !important;
  }
}