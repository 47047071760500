.wrapper {
  display: flex;
  row-gap: 1rem;
  column-gap: 1.5rem;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.card {
  width: 400px;
  background: #fff;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 4px rgba(51, 55, 68, 0.04);
  text-align: center;
}

.card:first-child + .card {
  padding-bottom: 5rem;
  background: #112656;
  .listItem {
    color: #fff;
  }
  .title {
    color: #112656;
    background: #fff;
  }
}

.listItem {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #333;
  padding-bottom: 1rem;
}

.title {
  background: #112656;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 1.875rem;
  padding: 4px 12px;
  font-size: .875rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #fff;
}