$brand: #ea8112;

.footer {
  background-color: #1e1e1e;
  width: 100%;
  padding: 1.25rem 0;
  font-family: 'Montserrat', sans-serif;

  section {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
  }
}


.content {
  max-width: 77.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.companyDetails {
  flex: 1;
  img {
    max-width: 12.5rem;
    margin-bottom: 1.5rem;
    height: auto;
  }
}

.text {
    font-size: .875rem;
    line-height:  1.25rem;
    max-width: 18.75rem;
    color: #ffffff;
    padding-bottom: 1.25rem;
}

.subHeading {
  font-size: 1.125rem;
  line-height:  1.5rem;
  font-weight: 700;
  color: #ffffff;
  padding-bottom: 1.25rem;  
}

.navItem > a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-size: .875rem;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: .25rem;
  &:hover {
    transition: color .3s;
    color: $brand;
  }
  &:hover::after {
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: $brand;
    transition: width .3s;
  }
}

.navItem {
  list-style: none;
}

.contactInfo {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: .5rem;
  p {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #fff;
    &:hover {
      transition: color .3s;
      color: $brand;
    }
    &:hover::after {
      width: 100%;
    }
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $brand;
      transition: width .3s;
    }
  }
} 

.socialIcons {
  display: flex;
  align-items: center;
  gap: 1rem;

  .icon {
    display: inherit;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff; 
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    &:hover {
      transition: border-color .3s;
      border-color: $brand;
    }
    & > div,
    img {
      height: .875rem;
      width: .875rem;
    }
  }    
}

.contactDetails {
  margin-left: 5rem;
}