.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-bottom: 4rem;
}

.subTitle {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #000;
}

.desc {
  font-size: .9375rem;
  line-height: 1.5rem;
  padding: 10px 0;
  font-weight: 500;
  color: #333;
}

.message {
  font-size: 1rem;
  text-align: center;
  line-height: 1.5rem;
  max-width: 98%;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #333;
}

.list {
  display: flex;
  row-gap: 4rem;
  column-gap: 4rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2.5rem;
}

.icon {
  width: 2rem;
  height: 2rem;
}

.titleSec {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.listItem {
  min-width: 18.75rem;
  max-width: 21.875rem;
  align-items: flex-start;
}