.wrapper {
  padding: 2.5rem 0;
  max-width: 77.5rem;
  margin: 5.625rem auto 0 auto;
}

.sectors {
  margin-top: 6rem;
}

.banner {
  display: flex;
  justify-content: center;
  flex-direction: column;

  height: 21.25rem;
  background: #fff;
  background-image: url("../../assets/images/Services/building.jpg");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  border-bottom: 2px solid #f0f3fc;
  margin-bottom: 5rem;
}

.border {
  height: .1563rem;
  width: 5rem;
  margin-top: .5rem;
  border-radius: 50%;
  background: #ea8112;
  margin-bottom: 2rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
  color: #000;
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2.875rem;
  max-width: 36.25rem;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
}

.title {
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  color: #000;
  position: relative;
  padding-top: 4rem;
}