.contentWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 5rem;
  align-items: center;
}

.subTitle {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #333;
}

.title {
  font-size: 2.125rem;
  line-height: 2.125rem;
  font-weight: 700;
  color: #000;
  padding: .75rem 0;
}

.message {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #333;
  margin: 1.25rem 0 2.5rem 0;
}

.servicesBtn {
  padding: .375rem 1rem;
  border: 2px solid #ea8112;
  border-radius: .25rem;
  text-decoration: none;
  background: #fff;

  font-size: .875rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #ea8112;
  transition: 0.3s;

  &:hover {
      color: #425b76;
      border-color: #425b76;
  }
}