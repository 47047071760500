.content {
  padding: 5rem 0;
  max-width: 77.5rem;
  margin: 0 auto;
}

.why,
.sectors,
.partners {
  margin-top: 10rem;
}

.title {
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 700;
  color: #000;
  position: relative;
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 90%;
  font-weight: 500;
  color: #616161;
  margin: 1.25rem 0 2.5rem 0;
}

.message {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #616161;
}

.border {
  height: .1563rem;
  width: 5rem;
  margin-top: .5rem;
  border-radius: 50%;
  background: #ea8112;
  margin-bottom: 4rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  padding-bottom: 3rem;
  align-items: center;
  & > img {
    width: 7.5rem;
    height: 7rem;
    &:last-child {
      width: 12.5rem;
      height: 4rem;
    }
  }
}