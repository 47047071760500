$brand: #ea8112;

.navList {
  display: flex;
  align-items: center;
  gap: 2.5rem;

  .navItem > a {
    display: inline-block;
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2em;
    word-break: normal;
    font-family: "Montserrat", sans-serif;
    &:hover {
      transition: color 0.3s;
      color: $brand;
    }
    &.active {
      color: $brand !important;
      border-bottom: 2px solid $brand;
    }
    &:hover::after {
      width: 100%;
    }
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: $brand;
      transition: width 0.3s;
    }
  }
}

.btnQuote {
  background: $brand;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration: none;
  height: auto;
  &:hover {
    background: #425b76 !important;
    border-color: #425b76 !important;
    color: #fff !important;
  }
}