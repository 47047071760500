.wrapper {
	position: fixed;
	z-index: 20;
	width: 100%;
	background: #fff;
	box-shadow: 0 5px 10px rgba(154,161,177,.1);
}

.navBar {
	display: flex;
	align-items: center;
	position: relative;
	max-width: 77.5rem;
	margin: 0 auto;
	padding: 1.25rem 2.5rem;
	justify-content: space-between;
	
	img {
		max-width: 12.5rem;
		height: auto;
	}
}