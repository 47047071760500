.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  row-gap: 6rem;
  column-gap: 3rem;
}

.message {
  font-size: .9375rem;
  line-height: 1.5rem;
  text-align: left;
  font-weight: 500;
  color: #000;
}

.title {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #000;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 380px;
}

.image {
  width: 380px;
  height: 250px;
}